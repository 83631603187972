import { get } from 'src/services/auth/CookieService';
import { permission, Role } from 'src/types/Roles';

export function getLoggedUserFullName(): string | null {
  const user = get('user');
  return user ? `${user.firstName} ${user.lastName}` : null;
}

export function getLoggedUserEmail(): string | null {
  const user = get('user');
  return user ? user.email : null;
}

export function getLoggedUserPermissions(): permission[]{
  const user = get('user');
  return user ? user.role.permissions : null;
}

export function getLoggedUserRole(): Role {
  const user = get('user');
  return user ? user.role : null;
}