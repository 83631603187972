import { CustomInput } from '../shared';
import { ChangeEvent, useState } from 'react';
import { LoginDTO } from 'src/types/dto/LoginDTO';
import { InputTypes } from 'src/constants/InputTypes';
type Props = {
  handleForgotPassword: (payload: LoginDTO) => Promise<void>;
};

export const ForgotPasswordForm: React.FC<Props> = ({
  handleForgotPassword,
}) => {
  const [form, setForm] = useState<LoginDTO>({ email: '', password: '' });
  const [errors, setErrors] = useState<string[]>([]);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    errors[event.target.name] = '';
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = async (): Promise<void> => {
    try {
      await handleForgotPassword(form);
    } catch (errors) {
      setErrors(errors);
    }
  };

  return (
    <div className="w-full p-6 space-y-8 sm:p-8 lg:p-16 lg:py-0">
      <h2 className="text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
        Recover your password
      </h2>
      <div className="mt-8 space-y-6">
        <CustomInput
          label="Your Email"
          type={InputTypes.email}
          name="email"
          id="email"
          placeholder="name@company.com"
          isRequired={true}
          onChange={(event) => inputChangeHandler(event)}
          error={errors['email']}
        />

        {/* {errors['email']} */}

        <div className="flex flex-col items-end lg:flex-row">
          <button
            onClick={onSubmit}
            className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Recover Password
          </button>
        </div>
      </div>
    </div>
  );
};
