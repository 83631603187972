import { useMemo, useState } from 'react';
import { DashboardLayout } from 'src/layouts';
import { Property, PropertyResponse } from 'src/types/Property';
import { Header } from 'src/components/shared/dashboard/Header';
import { Table } from 'src/components/property/list/Table';
import { list, remove } from 'src/api/Property';
import { notify } from 'src/services/NotificationService';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { PropertyPermissionsEnum } from 'src/constants/PropertyPermissionsEnum';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { Operator } from 'src/types/Operator';
import { list as operatorsList } from 'src/api/Operator';

export const Index: React.FC = () => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState<number>(null);
  const [operators, setOperators] = useState<Operator[]>([]);

  const getOperators = async (): Promise<void> => {
    const operators = await operatorsList();
    setOperators(operators);
  };

  const getProperties = async (): Promise<void> => {
    const propertyList = await list(pagination.currentPage);
    setProperties(propertyList.properties);
    setPagination({
      currentPage: propertyList.currentPage,
      totalPages: propertyList.totalPages,
    });
  };

  const nextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage + 1,
      });
    }
  };

  const previousPage = () => {
    if (pagination.currentPage > 1) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage - 1,
      });
    }
  };

  const handleDelete = async (): Promise<void> => {
    const response: PropertyResponse = await remove(selectedIdToDelete);
    const filteredArray = properties.filter((property: Property) => property.id !== selectedIdToDelete);
    setProperties(filteredArray);
    setIsConfirmationOpen(false);
    notify(response.message, 'success');
  };

  useMemo(() => {
    getProperties();
    getOperators();
  }, [pagination.currentPage]);

  const handleCancel = (): void => {
    setIsConfirmationOpen(false);
  };

  const showDeleteConfirmation = (userId: number): void => {
    setIsConfirmationOpen(true);
    setSelectedIdToDelete(userId);
  };

  return (
    <DashboardLayout>
      <Header 
        heading={'All Properties'}
        createButton={hasPermission(PropertyPermissionsEnum.create) && {
          link:'/dashboard/properties/create',
          label:'Add Property'
        }}
      />
      <Table
        propertList={properties}
        nextPage={nextPage}
        previousPage={previousPage}
        operatorsList={operators}
        onDelete={showDeleteConfirmation}
      />
      <ConfirmationDialog
        message={'Are you sure you want to delete this property?'}
        isOpen={isConfirmationOpen}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
};
