import { get, put, destroy, post } from 'src/api/Base';
import { objectToFormData } from 'src/helpers/ObjectHelper';
import {
  arrayApiToApp,
  apiToApp,
  loggedApiToApp,
} from 'src/mappers/UserMapper';
import {
  User,
  UserApi,
  UserCreateToApi,
  UserResponse,
  PaginatedUserList,
  UserToApi,
} from 'src/types/User';

async function list(
  page: number = 1,
  perPage: number = 10,
): Promise<PaginatedUserList> {
  const response = await get(`admin/users?page=${page}&per_page=${perPage}`);

  return {
    users: arrayApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    totalPages: response.data.data.meta.last_page,
  };
}

async function show(id: number): Promise<User> {
  const response = await get(`admin/users/${id}`);
  return apiToApp(response.data.data.user);
}

async function update(id: number, payload: UserToApi): Promise<UserResponse> {
  const formData = objectToFormData(payload);
  formData.append('_method', 'put');

  const response = await post(`admin/users/${id}`, formData);
  return response.data;
}

async function create(payload: UserCreateToApi): Promise<UserResponse> {
  const response = await post('admin/users', payload);
  return response.data;
}

async function remove(id: number): Promise<UserResponse> {
  const response = await destroy(`admin/users/${id}`);
  return response.data;
}

async function getLogged(): Promise<User> {
  const response = await get(`admin/me`);
  return loggedApiToApp(response.data.data.user);
}

export { list, show, update, remove, getLogged, create };
