import { useEffect, useState } from 'react';
import { CreateRoleModal } from 'src/components/roles/list/CreateRoleModal';
import { EditModal } from 'src/components/roles/list/EditModal';
import { Header } from 'src/components/shared/dashboard/Header';
import { Table } from 'src/components/roles/list/Table';
import { DashboardLayout } from 'src/layouts';
import { create, deleteRole, paginatedList, update } from 'src/api/Roles';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { RolesPermissionsEnum } from 'src/constants/RolesPermissionsEnum';
import {
  CreateRoleResponse,
  DeleteRoleResponse,
  Role,
  RoleToApi,
} from 'src/types/Roles';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { notify } from 'src/services/NotificationService';
import { list as permissionList } from 'src/api/Permission';
import { Permission } from 'src/types/Permission';

export const Index: React.FC = () => {
  const [showEditModal, setShowEditModal] = useState(false);

  const [editRole, setEditRole] = useState(null);
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [roles, setRoles] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState<number>(null);
  const [permissions, setPermissions] = useState<Permission[]>(null);

  const setRoleToEdit = (id: number) => {
    setEditRole(roles.find((item) => item.id === id));
    setShowEditModal(true);
  };

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const fetchAllRoles = async (page: number = 1, perPage: number = 10) => {
    try {
      const response = await paginatedList(page, perPage);
      // Filter roles
      response.roles = response.roles.filter(
        (role: Role) => role.name !== 'User',
      );

      setRoles(response.roles);
      setPagination({
        currentPage: response.currentPage,
        totalPages: response.totalPages,
      });
    } catch (error) {}
  };

  const getPermissions = async () => {
    const response: Permission[] = await permissionList();
    setPermissions(response);
  };

  const handleDelete = async (): Promise<void> => {
    const response: DeleteRoleResponse = await deleteRole(selectedIdToDelete);
    const filteredArray = roles.filter(
      (role: Role) => role.id !== selectedIdToDelete,
    );
    setRoles(filteredArray);
    setIsConfirmationOpen(false);
    notify(response.data.message, 'success');
  };

  useEffect(() => {
    fetchAllRoles();
    getPermissions();
  }, []);

  const nextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      fetchAllRoles(pagination.currentPage + 1);
    }
  };

  const previousPage = () => {
    if (pagination.currentPage > 1) {
      fetchAllRoles(pagination.currentPage - 1);
    }
  };

  const handleCancel = (): void => {
    setIsConfirmationOpen(false);
  };

  const showDeleteConfirmation = (userId: number): void => {
    setIsConfirmationOpen(true);
    setSelectedIdToDelete(userId);
  };

  const handleRoleCreate = async (payload: RoleToApi): Promise<void> => {
    const response: CreateRoleResponse = await create(payload);
    roles.push(response.data.data);
    setShowCreateRoleModal(false);
    notify(response.data.message || 'Role created successfully', 'success');
  };

  const handleRoleUpdate = async (
    id: number,
    payload: RoleToApi,
  ): Promise<void> => {
    const response: CreateRoleResponse = await update(id, payload);
    const index = roles.findIndex(
      (role: Role) => role.id === response.data.data.id,
    );
    if (index !== -1) {
      roles[index] = response.data.data;
    }
    setShowEditModal(false);
    notify(response.data.message || 'Role updated successfully', 'success');
  };

  return (
    <DashboardLayout>
      <Header
        heading={'All Roles'}
        createButtonModal={
          hasPermission(RolesPermissionsEnum.create) && {
            isModal: true,
            label: 'Add Role',
          }
        }
        handleOnClick={() => setShowCreateRoleModal(true)}
      />
      {showCreateRoleModal && (
        <CreateRoleModal
          onCloseModal={() => setShowCreateRoleModal(false)}
          permissions={permissions}
          onRoleCreate={handleRoleCreate}
        />
      )}
      <Table
        onEditModalButton={(id) => setRoleToEdit(id)}
        onDelete={showDeleteConfirmation}
        roleList={roles}
        nextPage={nextPage}
        previousPage={previousPage}
      />
      {showEditModal && (
        <EditModal
          Role={editRole}
          onCloseModal={() => setShowEditModal(false)}
          permissions={permissions}
          onRoleUpdate={handleRoleUpdate}
        />
      )}
      <ConfirmationDialog
        message={'Are you sure you want to delete this role?'}
        isOpen={isConfirmationOpen}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
};
