import { Permission, PermissionApi } from 'src/types/Permission';

export function arrayApiToApp(response: PermissionApi[]): Permission[] {
  return response.map(permissionApi => {
    return apiToApp(permissionApi);
  });
}

export function apiToApp(permissionApi: PermissionApi): Permission {
  return {
    id: permissionApi.id,
    name: permissionApi.name,
    slug: permissionApi.slug
  };
}