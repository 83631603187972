import AppLogo from 'src/assets/images/logos/azqira-green-on-transparent.png';
import LoginImage from 'src/assets/images/photos/auth/hotel.jpg';
import { LoginForm } from '../../components/auth';
import { LoginDTO } from 'src/types/dto/LoginDTO';
import { AuthLayout } from 'src/layouts';
import { useNavigate } from 'react-router-dom';
import { login } from 'src/api/Auth';

export default function NotFound() {
  const navigate = useNavigate();

  const handleLogin = async (dto: LoginDTO): Promise<void> => {
    await login(dto);
    navigate('/dashboard');
  };

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
        <a
          href="/dashboard"
          className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white"
        >
          <img
            src={AppLogo}
            className="mr-4 h-11"
            alt="App Logo"
          />
          <span>AZQIRA</span>
        </a>
        <div className="items-center justify-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-lg 2xl:max:max-w-screen-lg xl:p-0 dark:bg-gray-800">
          <div className="hidden w-2/3 lg:flex">
            <img
              className="rounded-l-lg"
              src={LoginImage}
              alt="login image"
            />
          </div>
          <div className="flex flex-col items-center w-full p-6 space-y-8 sm:p-8 lg:p-16 lg:py-0">
            <h2 className="text-2xl font-bold text-center text-gray-900 lg:text-3xl dark:text-white">
              404 Not Found
            </h2>
            <h5 className="text-2xl font-bold text-center text-gray-900 lg:text-3xl dark:text-white">
              Sorry, we couldn't find the page you're looking for :(
            </h5>

            <a
              href="/dashboard"
              className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mx-auto mt-[25px]"
            >
              Go back to homepage
            </a>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
