function set(key: string, payload: string): void {
  const toStore: string = payload;
  localStorage.setItem(key, toStore);
}

function reset(key: string): void {
  localStorage.removeItem(key);
}

function clear(): void {
  localStorage.clear();
}

export {set, reset, clear}
  