import React, { ReactNode, useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { getLocationObjectFromMaps } from 'src/helpers/LocationHelper';
import { Location } from 'src/types/Property';

type LocationInputProps = {
  defaultAddress?: string;
  onHandleChange: (mappedAddress: Location) => void;
  children: React.ReactNode;
};
const mapContainerStyle = {
  height: '400px',
  width: '100%',
};

const LocationInput = ({
  onHandleChange,
  defaultAddress = '',
  children,
}: LocationInputProps) => {
  const [address, setAddress] = useState(defaultAddress);
  const [center, setCenter] = useState({ lat: 39, lng: -105 });

  useEffect(() => {
    handleSelect(defaultAddress);
  }, []);

  const handleSelect = async (address: string) => {
    const results = await geocodeByAddress(address);

    const latLng = await getLatLng(results[0]);

    setAddress(address);
    setCenter(latLng);

    const mappedAddress = getLocationObjectFromMaps(
      results[0],
      latLng.lat,
      latLng.lng,
    );
    onHandleChange(mappedAddress);
  };

  const onMapLoad = (map) => {
    setCenter({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  };

  return (
    <div className="flex flex-col gap-y-5">
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <input
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              {...getInputProps({ placeholder: 'Enter address' })}
            />
            <div>
              <div className="absolute z-50 w-full rounded-lg">
                {suggestions.map((suggestion, index) => {
                  return (
                    <div
                      key={index}
                      className="p-2 cursor-pointer top-10 bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600"
                      {...getSuggestionItemProps(suggestion)}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <div className="flex flex-row w-full gap-x-6">
        {center && (
          <div className="w-1/2">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={10}
              onLoad={onMapLoad}
            >
              <Marker position={center} />
            </GoogleMap>
          </div>
        )}
          <div className="w-1/2">
            {children}
          </div>
      </div>
    </div>
  );
};

export default LocationInput;
