import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';
import { CreateForm } from 'src/components/property/forms/CreateForm';
import { notify } from 'src/services/NotificationService';
import { Property, PropertyResponse } from 'src/types/Property';
import { create } from 'src/api/Property';
import { useEffect, useState } from 'react';
import { Operator } from 'src/types/Operator';
import { list } from 'src/api/Operator';

export const Create: React.FC = () => {
  const [operators, setOperators] = useState<Operator[]>([]);

  const handleCreate = async (payload: Property): Promise<void> => {
    const response: PropertyResponse = await create(payload)
    notify(response.message, 'success');
  }

  const getOperators = async (): Promise<void> => {
    const operators = await list();
    setOperators(operators);
  };

  useEffect(() => {
    getOperators();
  }, []);

  return (
    <DashboardLayout>
      <Header
        heading={'Create Property'}
      />
      <CreateForm
        onPropertyCreate={handleCreate}
        operators={operators}
      />
    </DashboardLayout>
  );
};
