export function objectToFormData(obj: any, formData?: FormData, namespace?: string): FormData {
  formData = formData || new FormData();
  namespace = namespace || '';

  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }

    const propName = namespace ? `${namespace}[${key}]` : key;
    const propValue = obj[key];

    if (propValue instanceof Array) {
      propValue.forEach((value, index) => {
        const arrayKey = `${propName}[${index}]`;
        if (value instanceof File) {
          formData.append(arrayKey, value, value.name);
        } else {
          formData.append(arrayKey, value);
        }
      });
    } else if (propValue instanceof Object) {
      if (propValue instanceof File) {
        formData.append(propName, propValue, propValue.name);
      } else {
        objectToFormData(propValue, formData, propName);
      }
    } else {
      formData.append(propName, propValue);
    }
  }

  return formData;
}
