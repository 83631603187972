import { Permission } from 'src/types/Permission';
import { useState } from 'react';
import { RoleToApi } from 'src/types/Roles';

type Props = {
  onCloseModal: () => void;
  onRoleCreate: (payload: RoleToApi) => void;
  permissions: Permission[],
};

export function CreateRoleModal({ onCloseModal, permissions, onRoleCreate }: Props) {
  const [roleName, setRoleName] = useState<string>('');
  const [selectedPermissions, setSelectedPermissions] = useState<Array<number>>([]);

  const handleInputChange = (event: any): void => {
    const { value } = event.target;
    setRoleName(value);
  };

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const permissionId = parseInt(event.target.value);
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedPermissions([...selectedPermissions, permissionId]);
    } else {
      setSelectedPermissions(selectedPermissions.filter((n) => n !== permissionId));
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload: RoleToApi = {
      name: roleName,
      permissions: selectedPermissions
    }
    onRoleCreate(payload);
  };

  return (
    <div className="fixed left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 sm:h-full">
      <div onClick={onCloseModal} className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>

      <div className="relative w-full h-full max-w-2xl px-4 mt-[10%] m-auto md:h-auto pb-20">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 ">
          <form action="#" onSubmit={handleSubmit}>
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
                Create Role
              </h3>
              <button
                type="button"
                name="close"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={onCloseModal}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-6 gap-6">
                {/* Role Name */}
                <div className="col-span-6">
                  <label
                    htmlFor="role-name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Role Name
                  </label>
                  <input
                    onChange={handleInputChange}
                    value={roleName}
                    type="text"
                    name="role-name"
                    id="role-name"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="User"
                    required
                  />
                </div>
              </div>

              {/* Permissions */}

              <h3 className="mt-6 mb-4 font-semibold text-gray-900 dark:text-white">
                Permissions
              </h3>
              <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white max-h-[200px] overflow-y-scroll">
                {permissions.map(permission => (
                  <li key={permission.id} className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                    <div className="flex items-center pl-3">
                      <input
                        id={`prmsn${permission.id}`}
                        value={permission.id}
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        htmlFor={`prmsn${permission.id}`}
                        className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {permission.name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
              <button
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
