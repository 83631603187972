import AppLogo from 'src/assets/images/logos/azqira-green-on-transparent.png';
import LoginImage from 'src/assets/images/photos/auth/hotel.jpg';
import { AuthLayout } from 'src/layouts';
import { SetPasswordForm } from 'src/components/auth/SetPasswordForm';
import { setPassword } from 'src/api/Auth';
import { SetPasswordDTO } from 'src/types/dto/SetPasswordDTO';
import { UserResponse } from 'src/types/User';
import { notify } from 'src/services/NotificationService';


export function SetPassword() {
  const handleSetPassword = async (setPasswordDto: SetPasswordDTO): Promise<void> => {
    try {
      const response: UserResponse = await setPassword(setPasswordDto);
      notify(response.message, 'success');
      setTimeout(() => {
        window.location.href = '/';
      }, 1500);
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  return (
    <AuthLayout>

      <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
        <a
          href="../../"
          className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white"
        >
          <img
            src={AppLogo}
            className="mr-4 h-11"
            alt="App Logo"
          />
          <span>AZQIRA</span>
        </a>
        <div className="items-center justify-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-lg 2xl:max:max-w-screen-lg xl:p-0 dark:bg-gray-800">
          <div className="hidden w-2/3 lg:flex">
            <img
              className="rounded-l-lg"
              src={LoginImage}
              alt="login image"
            />
          </div>
          <SetPasswordForm handleSetPassword={handleSetPassword} />
        </div>
      </div>
    </AuthLayout>
  );
}
