import { get } from './CookieService';

export function GetAccessToken(): string | null {
  const auth = get('auth');
  return auth ? auth.access_token : null;
}
export function headers(): object {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  };
}

export function multipartFormHeaders(): object {
  return {
    'Content-Type': 'multipart/form-data',
  };
}

export function authHeaders(token: string): object {
  // Get access token from cache
  return { Authorization: `Bearer ${token}` };
}
