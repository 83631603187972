import dayjs from 'dayjs';

function secondsToDays(seconds: number): number {
  const dayInSeconds = 86400;
  return seconds / dayInSeconds;
}

function toIsoString(date: Date): string {
  return date.toISOString();
}

function toReadableString(date: Date): string {
  const format = 'DD/MM/YYYY';
  return dayjs(date).format(format);
}

function tomorrow(): Date {
  return new Date(Date.now() + 3600 * 1000 * 24);
}

function daysToMs(days: number) {
  return days * 24 * 60 * 60 * 1000;
}

function todayFormattedForInput() {
  return new Date().toISOString().split("T")[0]
}

function formattedForInput(date: Date): string {
  return date.toISOString().split("T")[0]
}

function timestampToDate(date: string): Date {
  return new Date(date)
}
export { secondsToDays, toIsoString, toReadableString, tomorrow, daysToMs, todayFormattedForInput, timestampToDate, formattedForInput};