import { useState } from "react";

type Props = {
  value: Number;
  hover: Number;
  onChangeRating: (rating: number) => void;
};

export function StarRating({
  value,
  hover,
  onChangeRating
}: Props) {
  const [localHover, setLocalHover] = useState(hover);
  
  const handleChange = (val: number): void => {
    onChangeRating(val);
  };

  return (
    <div className="star-rating" >
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={`bg-transparent border-0 outline-0 cursor-pointer ' ${index <= (value || hover) ? "text-primary-500" : "text-gray-700"}`}
            onClick={() => handleChange(index)}
            onMouseEnter={() => setLocalHover(index)}
            onMouseLeave={() => setLocalHover(value)}
          >
            <svg
              className="w-10 h-10 "
              fill="currentColor"
              stroke="#4B5462"
              strokeWidth={0.5}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path clipRule="evenodd" fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
            </svg>
          </button>
        );
      })}
    </div>
  );
};