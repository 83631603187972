import { useMemo, useState } from 'react';
import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';
import { Table } from 'src/components/operator/list/Table';
import { CreateModal } from 'src/components/operator/list/CreateModal';
import { paginatedList, update, remove, add } from 'src/api/Operator';
import { Operator, OperatorResponse } from 'src/types/Operator';
import { notify } from 'src/services/NotificationService';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { OperatorPermissionsEnum } from 'src/constants/OperatorPermissionsEnum';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';

export const List: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [operators, setOperators] = useState<Operator[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState<number>(null);

  const getOperators = async (): Promise<void> => {
    const operatorResponse = await paginatedList(pagination.currentPage);

    setOperators(operatorResponse.operators);
    setPagination({
      currentPage: operatorResponse.currentPage,
      totalPages: operatorResponse.currentPage,
    });
  };

  const previousPage = (): void => {
    if (pagination.currentPage > 1) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage - 1,
      });
    }
  };

  const nextPage = (): void => {
    if (pagination.currentPage < pagination.totalPages) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage + 1,
      });
    }
  };

  const handleDelete = async (): Promise<void> => {
    const response: OperatorResponse = await remove(selectedIdToDelete);
    const filteredArray = operators.filter(
      (operator: Operator) => operator.id !== selectedIdToDelete,
    );
    setOperators(filteredArray);
    setIsConfirmationOpen(false);
    notify(response.message, 'success');
  };

  const handleCreate = async (payload: any): Promise<void> => {
    const response: OperatorResponse = await add(payload);
    setShowCreateModal(false);
    notify(response.message, 'success');

    getOperators();
  };

  useMemo(() => {
    getOperators();
  }, [pagination.currentPage]);

  const handleCancel = (): void => {
    setIsConfirmationOpen(false);
  };

  const showDeleteConfirmation = (userId: number): void => {
    setIsConfirmationOpen(true);
    setSelectedIdToDelete(userId);
  };
  
  return (
    <DashboardLayout>
      <Header
        heading={'All Operators'}
        createButtonModal={hasPermission(OperatorPermissionsEnum.create) && {
          isModal: true,
          label:'Add Operator',
        }}
        handleOnClick={() => setShowCreateModal(true)}
      />
      {showCreateModal && (
        <CreateModal
          onCloseModal={() => setShowCreateModal(false)}
          onOperatorAdd={handleCreate}
        />
      )}
      <Table
        onDelete={showDeleteConfirmation}
        operatorList={operators}
        nextPage={nextPage}
        previousPage={previousPage}
      />
      <ConfirmationDialog
        message={'Are you sure you want to delete this operator?'}
        isOpen={isConfirmationOpen}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
};
