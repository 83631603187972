function isNull(value: any): boolean {
  const values = [null, 'null'];
  return values.includes(value);
}

function isUndefined(value: any): boolean {
  const values = [undefined, 'undefined'];
  return values.includes(value);
}

function isUndefinedOrNull(value: any): boolean {
  return isUndefined(value) || isNull(value);
}

function isEmptyString(value: any): boolean {
  return value === '';
}

function isString(value: unknown): boolean {
  return typeof value === 'string';
}

function isNumber(value: unknown): boolean {
  return typeof value === 'number';
}

export { isNull, isUndefined, isUndefinedOrNull, isEmptyString, isString, isNumber };