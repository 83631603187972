import { Role, RoleApi, permission, PermissionApi } from 'src/types/Roles';

export function arrayFromApiToApp(payload: RoleApi[]): Role[] {
  const roles: Role[] = [];

  payload.forEach((role) => {
    const rolePermissions: permission[] = rolePermissionsFromApiToApp(
      role.permissions,
    );

    const object: Role = {
      id: role.id,
      name: role.name,
      permissions: rolePermissions,
    };

    roles.push(object);
  });
  return roles;
}

export function fromApiToApp(roleFromApi: RoleApi): Role {
  const rolePermissions: permission[] = rolePermissionsFromApiToApp(
    roleFromApi.permissions,
  );

  const role: Role = {
    id: roleFromApi.id,
    name: roleFromApi.name,
    permissions: rolePermissions,
  };

  return role;
}

export function rolePermissionsFromApiToApp(permissions: PermissionApi[]): permission[] {
  return permissions.map((permission: PermissionApi) => {
    return {
      id: permission.id,
      name: permission.name,
      slug: permission.slug,
    };
  });
}
