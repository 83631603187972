import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from 'src/api/Auth';
import AppLogo from 'src/assets/images/logos/azqira-green-on-transparent.png';
import { getLoggedUserFullName, getLoggedUserEmail } from 'src/services/UserService';

type Props = {
  handleOpen: (value: boolean) => void;
}
export const Navbar: React.FC<Props> = ({handleOpen}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userFullName, setUserFullName] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();

  const toggleDropdownMenu = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const userFullNAme: string = getLoggedUserFullName();
    setUserFullName(userFullNAme);

    const userEmail: string = getLoggedUserEmail();
    setUserEmail(userEmail);
  }, []);


  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error)
    } finally {
      navigate('/');
    }
  };

  const toggleSidebar = () => {
    handleOpen(!isSidebarOpen);
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className="fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-5 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <button
              data-drawer-target="logo-sidebar"
              data-drawer-toggle="logo-sidebar"
              aria-controls="logo-sidebar"
              type="button"
              className="inline-flex items-center p-2 mt-2 mr-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={() => toggleSidebar()}
            >
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>
            <a
              href="/dashboard"
              className="flex items-center mx-5 h-11"
            >
              <img
                src={AppLogo}
                className="h-8 mr-4"
                alt="App Logo"
              />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                Azqira
              </span>
            </a>
          </div>
          <div className="flex items-center">
            <div className="relative flex items-center ml-3">
              <div>
                <button
                  type="button"
                  className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                  id="user-menu-button-2"
                  aria-expanded="false"
                  data-dropdown-toggle="dropdown-2"
                  onClick={toggleDropdownMenu}
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="w-8 h-8 rounded-full"
                    src="https://ui-avatars.com/api/?name=Az+Qira&color=7F9CF5&background=EBF4FF"
                    alt="user photo"
                  />
                </button>
              </div>

              <div
                className={`z-50 ${isOpen ? 'visible' : 'hidden'
                  } my-4 text-base absolute list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 top-0 right-5`}
                id="dropdown-2"
              >
                <div
                  className="px-4 py-3"
                  role="none"
                >
                  <p
                    className="text-sm text-gray-900 dark:text-white"
                    role="none"
                  >
                    {userFullName}
                  </p>
                  <p
                    className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                    role="none"
                  >
                    {userEmail}
                  </p>
                </div>
                <ul
                  className="py-1"
                  role="none"
                >
                  <li>
                    <Link
                      to={'/dashboard/profile'}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                      role="menuitem"
                    >
                      Profile
                    </Link>
                  </li>
                  

                  <li>
                    <a
                      className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                      role="menuitem"
                      onClick={handleLogout}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
