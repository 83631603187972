import {
  GoogleAddressComponent,
  GoogleMapLocation,
  Location,
} from 'src/types/Property';

export function getLocationObjectFromMaps(
  googleMapLocation: GoogleMapLocation,
  latitude: number,
  longitude: number,
): Location {
  const addressComponents = googleMapLocation.address_components;

  return {
    countryName: _getValueFromType(addressComponents, 'country').longName,
    countryCode: _getValueFromType(addressComponents, 'country').shortName,
    city: _getValueFromType(addressComponents, 'locality').longName,
    formattedAddress: googleMapLocation.formatted_address,
    latitude: latitude,
    longitude: longitude,
  };
}

function _getValueFromType(addressComponents: GoogleAddressComponent[], type: string): { shortName: string; longName: string } {
  const location = addressComponents.find(
    (addressComponent: GoogleAddressComponent) =>
      addressComponent.types.some((locationType) => locationType === type),
  );
  return location
    ? { shortName: location.short_name, longName: location.long_name }
    : { shortName: '', longName: '' };
}
