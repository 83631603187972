import { isUndefinedOrNull, isEmptyString } from './ValueHelper';

function stringToJSON<T = any>(payload: string): unknown | null {
  if (isUndefinedOrNull(payload) || isEmptyString(payload)) return null;

  return JSON.parse(payload) as unknown as T;
}

function JSONToString(payload: object): string | null {
  if (isUndefinedOrNull(payload)) return null;

  return JSON.stringify(payload);
}

export { stringToJSON, JSONToString }