import { CustomInput } from '../shared';
import { ChangeEvent, useState, useEffect } from 'react';
import { SetPasswordDTO } from 'src/types/dto/SetPasswordDTO';
import { InputTypes } from 'src/constants/InputTypes';
type Props = {
  handleSetPassword: (payload: SetPasswordDTO) => Promise<void>;
};

export const SetPasswordForm: React.FC<Props> = ({ handleSetPassword }) => {
  const [form, setForm] = useState<SetPasswordDTO>({
    password: '',
    passwordConfirmation: '',
    token: '',
  });
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      setForm({ ...form, token });
    }
  }, []);

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    errors[event.target.name] = '';
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = async (): Promise<void> => {
    try {
      await handleSetPassword(form);
    } catch (errors) {
      setErrors(errors.data.data ? errors.data.data : [errors.data.message]);
    }
  };

  return (
    <div className="w-full p-6 space-y-8 sm:p-8 lg:p-16 lg:py-0">
      <h2 className="text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
        Set your password
      </h2>
      <div className="mt-8 space-y-6">
        <CustomInput
          label="Set password"
          type={InputTypes.password}
          name="password"
          id="password"
          placeholder="********"
          isRequired={true}
          onChange={(event) => inputChangeHandler(event)}
        />

        <CustomInput
          label="Confirm password"
          type={InputTypes.password}
          name="passwordConfirmation"
          id="passwordConfirmation"
          placeholder="********"
          isRequired={true}
          onChange={(event) => inputChangeHandler(event)}
        />

        {errors.length > 0 && (
          <ul className="text-red-500">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}

        <div className="flex flex-col items-end lg:flex-row">
          <button
            onClick={onSubmit}
            className="w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Set Password
          </button>
        </div>
      </div>
    </div>
  );
};
