import { DashboardLayout } from 'src/layouts';
import { useEffect, useState } from 'react';
import { LoggedUser, User, UserApi, UserResponse } from 'src/types/User';
import { ProfileMainCard } from 'src/components/user/ProfileMainCard';
import { ProfilePasswordForm } from 'src/components/user/ProfilePasswordForm';
import { ProfileForm } from 'src/components/user/ProfileForm';
import { getLogged, update } from 'src/api/User';
import { notify } from 'src/services/NotificationService';
import { set } from 'src/services/auth/CookieService';
import { apiToApp } from 'src/mappers/UserMapper';
import { getLoggedUserRole } from 'src/services/UserService';
import { Role } from 'src/types/Roles';

export const Profile: React.FC = () => {
  const [loggedUser, setLoggedUser] = useState<User | null>(null);

  useEffect((): void => {
    const getLoggedUser = async () => {
      const loggedUser = await getLogged();
      setLoggedUser(loggedUser);
    };
    getLoggedUser();
  }, []);

  const handleUpdate = async (payload: User): Promise<void> => {
    const formattedPayload: UserApi = {
      id: payload.id,
      first_name: payload.firstName,
      last_name: payload.lastName,
      email: payload.email,
      verified: payload.verified,
      disabled: payload.disabled,
      kyc: payload.kyc,
      address: payload.address,
      phone_number: payload.phoneNumber,
      role_id: payload.roleId,
    }
    const response: UserResponse = await update(payload.id, formattedPayload);
    const userApi: User = apiToApp(response.data.data)
    const role: Role = getLoggedUserRole()
    const loggedUser: LoggedUser = { ...userApi, 'role': role};
    set('user', loggedUser);
    notify(response.message, 'success');
  };

  return (
    <DashboardLayout>
      <div className="p-[25px] mx-auto flex justify-center">
        <div className="grid w-full grid-cols-1 gap-4 px-4 pt-6 xl:grid-cols-3">
          <div className="mb-4 col-span-full xl:mb-2">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              Profile
            </h1>
          </div>
          <div className="h-full col-span-full xl:col-auto">
            <ProfilePasswordForm />
          </div>
          <div className="h-full col-span-full xl:col-span-2">
            {loggedUser && (
            <ProfileForm 
              user={loggedUser} 
              onProfileUpdate={handleUpdate}
            />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
