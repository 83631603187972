import { Property } from "src/types/Property";

type Props = {
  property: Property;
};

export function SingleProperty({
  property,
}: Props) {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:p-6 xl:p-8">
      <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Name
          </dt>
          <dd className="text-sm font-semibold text-gray-900 dark:text-white">
            {property?.name}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Number of Rooms
          </dt>
          <dd className="text-sm font-semibold text-gray-900 dark:text-white">
            {property?.numberOfRooms}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Type
          </dt>
          <dd className="text-sm font-semibold text-gray-900 dark:text-white">
            {property?.typeId}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Description
          </dt>
          <dd className="text-sm font-semibold text-gray-900 dark:text-white">
            <p>
              {property?.description}
            </p>
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Operator
          </dt>
          <dd className="text-sm font-semibold text-gray-900 dark:text-white">
            {property?.operatorId}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Opening Date
          </dt>
          <dd className="text-sm font-semibold text-gray-900 dark:text-white">
            {property?.openingDate}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Website
          </dt>
          <dd className="text-sm font-semibold text-gray-900 dark:text-white">
            {property?.website}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Hotel Rating
          </dt>
          <dd className="flex text-sm font-semibold text-gray-900 dark:text-white ">
            {Array.apply(null, { length: property?.rating }).map((e, i) => (
              <svg
              key={i}
              className="w-10 h-10 text-primary-500"
              fill="currentColor"
              stroke="#4B5462"
              strokeWidth={0.5}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path clipRule="evenodd" fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
            </svg>
            ))}
          </dd>
        </div>
    </div>
  );
}
