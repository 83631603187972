import { DashboardLayout } from 'src/layouts';
import { Property } from 'src/types/Property';
import { Header } from 'src/components/shared/dashboard/Header';
import { SingleProperty } from '../../components/property/SingleProperty';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { show } from 'src/api/Property';

export const Show: React.FC = () => {
  const [property, setProperty] = useState<Property>();
  const { id } = useParams();

  useEffect(() => {
    const getProperty = async (): Promise<void> => {
      const property = await show(parseInt(id));
      setProperty(property);
    };
    getProperty();
  }, [id]);

  return (
    <DashboardLayout>
      <Header 
        heading={'Property Details'}
      />
      <SingleProperty
        property={property}
      />
    </DashboardLayout>
  );
};
