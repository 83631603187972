import { useRef, useState } from "react"
import { appToApi } from "src/mappers/UserMapper"
import { Role } from "src/types/Roles"
import { User, UserApi } from "src/types/User"
import placeholderImage from 'src/assets/images/placeholder.jpg';

type Props = {
  user: User,
  roles: Role[],
  onUserUpdate: (userId: number, payload: UserApi) => void
}

export const EditForm = ({ onUserUpdate, user, roles }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<User>(user);
  const [profileImage, setProfileImage] = useState<File>(undefined);
  let inputElement = useRef<HTMLInputElement>(undefined);
  const [roleValue, setRoleValue] = useState<number>(user.roleId);
  const [checkboxes, setCheckboxes] = useState({
    verified: user.verified,
    disabled: user.disabled,
    kyc: user.kyc
  });

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, checked } = event.target;
    setCheckboxes({ ...checkboxes, [name]: checked });
  }

  function handleRoleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    setRoleValue(parseInt(event.target.value));
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target.name;
    const file = event.target.files[0];
    const reader = new FileReader();
    setProfileImage(file);
    reader.onload = () => {
      setInputValues({ ...inputValues, [name]: reader.result as string });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    setIsSubmitting(true);
    event.preventDefault();

    const payload: UserApi = appToApi(inputValues, profileImage);

    onUserUpdate(user.id, payload)
    setIsSubmitting(false);
  };

  return (
    <div className="relative w-full h-full">
      {/* <!-- Modal content --> */}
      <div className="relative h-full bg-white shadow dark:bg-gray-800">
        <form
          action="#"
          name=""
          onSubmit={handleSubmit}
        >
          {/* <!-- Modal body --> */}
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 m-auto sm:col-span-6">
                <div className="relative w-48 h-48 overflow-hidden rounded-full">
                  <input ref={input => inputElement.current = input} name="profilePhotoUrl" onChange={handleImageChange} type="file" className="hidden" />
                  <div onClick={() => { inputElement.current.click() }} className="absolute top-0 left-0 w-full h-full group hover:bg-opacity-50 hover:bg-black">
                    <span className="absolute items-center justify-center hidden w-full h-full m-auto text-center text-white cursor-pointer group-hover:flex">
                      change profile photo
                    </span>
                  </div>
                  <img src={inputValues.profilePhotoUrl ?? placeholderImage} alt="" className="object-cover w-full h-full" />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                <input type="text"
                  value={inputValues.firstName || ''}
                  onChange={handleChange}
                  name="firstName" id="firstName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Bonnie" required />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                <input type="text"
                  value={inputValues.lastName || ''}
                  onChange={handleChange}
                  name="lastName" id="lastName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Green" required />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <input type="email"
                  value={inputValues.email || ''}
                  onChange={handleChange}
                  name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="example@company.com" required />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Address</label>
                <input type="text"
                  value={inputValues.address || ''}
                  onChange={handleChange}
                  name="address" id="address" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Street Address, City, State/Province, Postal Code, Country" />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                <input type="number"
                  value={inputValues.phoneNumber || ''}
                  onChange={handleChange}
                  name="phoneNumber" id="phoneNumber" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="e.g. +(12)3456 789" />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                <select
                  value={roleValue}
                  onChange={handleRoleChange}
                  id="role" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  {roles.map(role => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <input
                  checked={checkboxes.verified}
                  onChange={handleCheckboxChange}
                  name="verified" id="cbVerified" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor="cbVerified" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Verified</label>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <input
                  checked={checkboxes.disabled}
                  onChange={handleCheckboxChange}
                  name="disabled" id="cbDisabled" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor="cbDisabled" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Disabled</label>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <input checked={checkboxes.kyc}
                  onChange={handleCheckboxChange}
                  name="kyc" id="cbKyc" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor="cbKyc" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">KYC</label>
              </div>
            </div>
          </div>
          {/* <!-- Modal footer --> */}
          <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button disabled={isSubmitting} className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" type="submit">Save all</button>
          </div>
        </form>
      </div>
    </div>
  )
}