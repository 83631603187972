import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { list } from "src/api/Roles";
import { show, update } from "src/api/User";
import { EditForm } from "src/components/user/EditForm";
import { Header } from "src/components/user/list/Header"
import { DashboardLayout } from "src/layouts"
import { notify } from "src/services/NotificationService";
import { Role } from "src/types/Roles";
import { User, UserResponse } from "src/types/User";

export const Edit: React.FC = () => {
  const [user, setUser] = useState<User>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const { id } = useParams();


  useEffect(() => {
    const getUser = async (): Promise<void> => {
      const user = await show(parseInt(id));
      setUser(user);
    };
    const getRoles = async (): Promise<void> => {
      const roles = await list();
      setRoles(roles);
    }
    getUser();
    getRoles();
  }, [id]);

  const handleUpdate = async (userId: number, payload: any): Promise<void> => {
    const response: UserResponse = await update(userId, payload);
    notify(response.message, 'success');
  };
  
  return (
    <DashboardLayout>
      <div className="flex flex-col h-full">
        <div className="flex-none">
          <Header
            heading={'Edit User'}
          />
        </div>
        <div className="grow">
          {user && (
            <EditForm
              user={user}
              roles={roles}
              onUserUpdate={handleUpdate}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
