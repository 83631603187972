import { useState } from 'react';
import { Flip, ToastContainer } from 'react-toastify';
import { Navbar, Sidebar } from 'src/components/shared/dashboard';
type Props = {
  children: React.ReactNode; // 👈️ type children
};
export function DashboardLayout({ children }: Props) {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const toggleSidebar = (value: boolean) => {
    setOpenSidebar(value)
  }
  return (
    <div className="flex flex-1 ">
      <div>
        <Navbar handleOpen={(value) => toggleSidebar(value)} />
      </div>
      <Sidebar isOpen={openSidebar} />
      <main className="pt-[60px] pl-0 lg:pl-[256px] bg-gray-50 dark:bg-gray-900 w-full">
        {children}
      </main>

      {/* Notifications */}
      {/* <ToastContainer
        position="bottom-center"
        transition={Flip}
      /> */}
      {/* /Notifications */}
    </div>
  );
}
