import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';
import { Property, PropertyResponse } from 'src/types/Property';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { show, update } from 'src/api/Property';
import { notify } from 'src/services/NotificationService';
import { Operator } from 'src/types/Operator';
import { list } from 'src/api/Operator';
import { EditForm } from 'src/components/property/forms/EditForm';

export const Edit: React.FC = () => {
  const [property, setProperty] = useState<Property>();
  const { id } = useParams();
  const [operators, setOperators] = useState<Operator[]>([]);

  const getOperators = async (): Promise<void> => {
    const operators = await list();
    setOperators(operators);
  };

  useEffect(() => {
    const getProperty = async (): Promise<void> => {
      const property = await show(parseInt(id));
      setProperty(property);
    };
    getProperty();
    getOperators();
  }, [id]);

  const handleUpdate = async (payload: Property): Promise<void> => {
    const response: PropertyResponse = await update(payload.id, payload);
    notify(response.message, 'success');
  };

  return (
    <DashboardLayout>
      <Header
        heading={'Edit Property'}
      />
      {property && (
        <EditForm
          property={property}
          onPropertyUpdate={handleUpdate}
          operators={operators}
        />
      )}
    </DashboardLayout>
  );
};