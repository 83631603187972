import { Operator, OperatorApi, OperatorToApi } from 'src/types/Operator';
import { ImageFile } from 'src/types/Property';

export function arrayApiToApp(response: OperatorApi[]): Operator[] {
  return response.map(operatorApi => {
    return apiToApp(operatorApi);
  });
}

export function apiToApp(operatorApi: OperatorApi): Operator {
  return {
    id: operatorApi.id,
    name: operatorApi.name,
    description: operatorApi.description,
    logoUrl: operatorApi.logo_url,
    createdAt: operatorApi.created_at,
    updatedAt: operatorApi.updated_at,
  };
}

export function appToApi(operator: Operator, logo?: File): OperatorToApi {
  return {
    id: operator.id,
    name: operator.name,
    description: operator.description,
    logo: logo,
    created_at: operator.createdAt,
    updated_at: operator.updatedAt,
  };
}

