import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { GetAccessToken } from 'src/services/auth/AuthService';
import { notify } from 'src/services/NotificationService';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children, permission = null }) => {

  const accessToken = GetAccessToken();
  
  if (accessToken === null) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  if (permission && !hasPermission(permission)) {
    notify("you are not allowed to perform this action", "error");
    return <Navigate to="/dashboard"/>;
  }
  return children;
};
