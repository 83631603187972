import { post } from 'src/api/Base';
import { clear as ClearCache } from 'src/services/auth/CacheService';
import { clear as ClearCookies, set } from 'src/services/auth/CookieService';
import { LoginResponse } from 'src/types/Auth';
import { LoginDTO } from 'src/types/dto/LoginDTO';
import { UserResponse } from 'src/types/User';
import { getLogged } from './User';
import { SetPasswordDTO } from 'src/types/dto/SetPasswordDTO';
import { SetPasswordAppToApi } from 'src/mappers/UserMapper';

async function login(payload: LoginDTO): Promise<LoginResponse> {
  const auth = await post(`admin/login`, payload);
  set('auth', auth.data.data);
  const response = await getLogged();
  set('user', response);

  return auth.data;
}

async function logout(): Promise<void> {
  await post(`logout`);
  ClearCookies();
  ClearCache();

  return;
}

async function forgotPassword(payload: LoginDTO): Promise<UserResponse> {
  const response = await post(`forgot-password`, payload);

  return response.data;
}

async function setPassword(payload: SetPasswordDTO): Promise<UserResponse> {
  const response = await post(`set-password`, SetPasswordAppToApi(payload));

  return response.data;
}

export { login, logout, forgotPassword, setPassword };
