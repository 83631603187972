import Select from 'react-select';

type Props = {
  options: any[];
  defaultValue?: object;
  handleChange?: (e) => void;
};

const SingleSelect: React.FC<Props> = ({
  options,
  defaultValue,
  handleChange
}) => {
  return (
  <Select
    isClearable
    value={defaultValue}
    options={options}
    onChange={handleChange}
    styles={{
      option: provided => ({
        ...provided,
        backgroundColor: '#374050',
        color: 'white',
        "&:hover": {
          backgroundColor: "#248AFB"
        }
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: '#374050'
      }),
      singleValue: provided => ({
        ...provided,
        color: 'white',
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: '#374050',
        color: 'white',
        borderRadius: '0.5rem',
        border: state.isFocused ? '2px solid #00C055' : '1px solid #4B5462',
        boxShadow:  "none",
        height: '42px',
        "&:hover": {
          border: '2px solid #00C055',
        }
      }),
      input: provided => ({
        ...provided,
        color: 'white',
      })
    }}
  />
)};

export default SingleSelect;