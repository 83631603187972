import { useEffect, useMemo, useState } from 'react';
import { DashboardLayout } from 'src/layouts';
import { User, UserResponse } from 'src/types/User';
import { Header } from 'src/components/shared/dashboard/Header';
import { Table } from 'src/components/user/list/Table';
import { Link } from 'react-router-dom';
import { list, update } from 'src/api/User';
import { list as roleList } from 'src/api/Roles';
import { remove } from 'src/api/User';
import { notify } from 'src/services/NotificationService';
import { apiToApp } from 'src/mappers/UserMapper';
import { ConfirmationDialog } from 'src/components/shared/dialog/ConfirmationDialog';
import { Role } from 'src/types/Roles';
import { filterListByRole } from 'src/helpers/UserHelper';
import { UserPermissionsEnum } from 'src/constants/UserPermissionsEnum';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';

export const UserList: React.FC = () => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState<number>(null);

  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const getUsers = async (): Promise<void> => {
    const userList = await list(pagination.currentPage);
    setUsers(userList.users);
    setPagination({
      currentPage: userList.currentPage,
      totalPages: userList.totalPages,
    });
  };
  const getRoles = async (): Promise<void> => {
    const roles = await roleList();
    setRoles(roles);
  };

  const handleDelete = async (): Promise<void> => {
    const response: UserResponse = await remove(selectedIdToDelete);
    const filteredArray = users.filter(
      (user) => user.id !== selectedIdToDelete,
    );
    setUsers(filteredArray);
    setIsConfirmationOpen(false);
    notify(response.message, 'success');
  };

  useEffect(() => {
    const filteredUsers = filterListByRole(users, roles, ['User']);
    setFilteredUsers(filteredUsers);
  }, [roles]);

  useMemo(() => {
    getUsers();
  }, [pagination.currentPage]);

  useEffect(() => {
    getRoles();
  }, [users]);

  const nextPage = (): void => {
    if (pagination.currentPage < pagination.totalPages) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage + 1,
      });
    }
  };

  const previousPage = (): void => {
    if (pagination.currentPage > 1) {
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage - 1,
      });
    }
  };

  const handleCancel = (): void => {
    setIsConfirmationOpen(false);
  };

  const showDeleteConfirmation = (userId: number): void => {
    setIsConfirmationOpen(true);
    setSelectedIdToDelete(userId);
  };

  return (
    <DashboardLayout>
      <Header 
        heading={'All Users'} 
        createButton={hasPermission(UserPermissionsEnum.create) && {
          link:'/dashboard/users/create',
          label:'Create User'
        }}
      />
      <Table
        onDelete={showDeleteConfirmation}
        userList={filteredUsers}
        nextPage={nextPage}
        previousPage={previousPage}
      />
      <ConfirmationDialog
        message={'Are you sure you want to delete this user?'}
        isOpen={isConfirmationOpen}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
};
