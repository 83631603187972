import { useRef, useState } from "react"
import { createUserAppToApi } from "src/mappers/UserMapper"
import { Role } from "src/types/Roles"
import { UserCreate, UserCreateToApi } from "src/types/User"

type Props = {
  roles: Role[],
  onUserCreate: (payload: UserCreateToApi) => void
}

export const CreateForm = ({ onUserCreate, roles }: Props) => {
  const initialValue: UserCreate = {
    firstName: '',
    lastName: '',
    email: '',
    roleId: 1,
  }

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [user, setUser] = useState<UserCreate>(initialValue);

  function handleRoleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    setUser((prevState) => ({
      ...prevState,
      roleId: parseInt(event.target.value)
    }));
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    setIsSubmitting(true);
    event.preventDefault();

    const payload: UserCreateToApi = createUserAppToApi(user);
    
    onUserCreate(payload);
    setUser(initialValue);
    setIsSubmitting(false);
  };

  return (
    <div className="relative w-full h-full">
      {/* <!-- Modal content --> */}
      <div className="relative h-full bg-white shadow dark:bg-gray-800">
        <form
          action="#"
          onSubmit={handleSubmit}
        >
          {/* <!-- Modal body --> */}
          <div className="p-6 space-y-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                <input type="text"
                  value={user.firstName}
                  onChange={handleChange}
                  name="firstName" id="firstName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Bonnie" required />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                <input type="text"
                  value={user.lastName}
                  onChange={handleChange}
                  name="lastName" id="lastName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Green" required />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <input type="email"
                  value={user.email}
                  onChange={handleChange}
                  name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="example@company.com" required />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                <select
                  value={user.roleId}
                  onChange={handleRoleChange}
                  id="role" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  {roles.map(role => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* <!-- Modal footer --> */}
          <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button disabled={isSubmitting} className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" type="submit">Save all</button>
          </div>
        </form>
      </div>
    </div>
  )
}