import { Role } from "src/types/Roles"
import { User } from "src/types/User"

export function filterListByRole(usersList: User[], roles: Role[], roleNames: string[]): User[] {
  const filteredRoles = roles.filter(role => roleNames.includes(role.name))
  const filteredUsers = usersList.filter(user => filteredRoles.some(role => role.id === user.roleId))
  return filteredUsers
}

export function filterListByNotRole(usersList: User[], roles: Role[], roleNames: string[]): User[] {
  const filteredRoles = roles.filter(role => !roleNames.includes(role.name))
  const filteredUsers = usersList.filter(user => filteredRoles.some(role => role.id === user.roleId))
  return filteredUsers
}