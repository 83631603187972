import React, { useEffect, useState } from 'react';
import { PropertyPackage } from 'src/types/Property';

type Props = {
  packageData?: PropertyPackage,
  submit: (payload: PropertyPackage) => void,
}

export function PackageForm({ packageData, submit }: Props) {
  const packageInitalValue = packageData ? packageData :
    {
      imageUrl: '',
      image: null,
      price: 0,
      azqAmount: 0,
      description: '',
    }

  const [formData, setFormData] = useState<PropertyPackage>(packageInitalValue);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file,
        imageUrl: reader.result as string,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleAzqAmountKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isBackspace = e.key === 'Backspace';
    if (!/^[0-9]+$/.test(e.key) && !isBackspace) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    submit(formData);
  }, [formData]);

  return (
    <div className="grid grid-cols-6 col-span-6 gap-6 py-2">
      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="image"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Image
        </label>

        <input
          type="file"
          name="image"
          id="image"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          required={formData.imageUrl === ''}
        ></input>
      </div>

      <div className="col-span-6 sm:col-span-3">
        {formData.imageUrl &&
          <img className="w-24 h-full" src={formData.imageUrl && formData.imageUrl} alt="" />
        }
      </div>

      <div className="col-span-3">
        <label
          htmlFor="azqAmount"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          AZQ Amount
        </label>
        <input
          type="number"
          name="azqAmount"
          id="azqAmount"
          value={formData.azqAmount}
          onKeyDown={handleAzqAmountKeyDown}
          onChange={handleChange}
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          required
        ></input>
      </div>
      <div className="col-span-3">
        <label
          htmlFor="description"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Description
        </label>
        <textarea
          name="description"
          rows={5}
          id="description"
          value={formData.description}
          onChange={handleChange}
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        ></textarea>
      </div>
    </div>
  );
}
