import { formattedForInput, timestampToDate } from 'src/helpers/DateHelper';
import {
  ImageFile,
  Property,
  PropertyApi,
  PropertyPackage,
  PropertyPackageApi,
  PropertyPackageToApi,
  PropertyPackages,
  PropertyToApi,
} from 'src/types/Property';

export function arrayApiToApp(response: PropertyApi[]): Property[] {
  return response.map((propertyApi) => {
    return apiToApp(propertyApi);
  });
}

export function apiToApp(propertyApi: PropertyApi): Property {
  return {
    id: propertyApi.id,
    name: propertyApi.name,
    description: propertyApi.description,
    numberOfRooms: propertyApi.number_of_rooms,
    rating: propertyApi.rating,
    openingDate: propertyApi.opening_date,
    website: propertyApi.website,
    operatorId: propertyApi.operator_id,
    typeId: propertyApi.type_id,
    features: propertyApi.features,
    images: propertyApi.images ? _getImages(propertyApi.images) : [],
    location: {
      countryName: propertyApi.location
        ? propertyApi.location.country_name
        : '',
      countryCode: propertyApi.location
        ? propertyApi.location.country_code
        : '',
      city: propertyApi.location ? propertyApi.location.city : '',
      formattedAddress: propertyApi.location
        ? propertyApi.location.formatted_address
        : '',
      latitude: propertyApi.location ? propertyApi.location.latitude : '',
      longitude: propertyApi.location ? propertyApi.location.longitude : '',
    },
    locationDescription: propertyApi.location
      ? propertyApi.location.description
      : null,
    financial: {
      launchDate: propertyApi.financial.launch_date,
      minimumHoldingTime: propertyApi.financial.minimum_holding_time,
      propertyValuation: propertyApi.financial.property_valuation,
    },
    packages: {
      bronze: _getPackageFromApi(propertyApi.packages, 'Bronze'),
      silver: _getPackageFromApi(propertyApi.packages, 'Silver'),
      gold: _getPackageFromApi(propertyApi.packages, 'Gold'),
      diamond: _getPackageFromApi(propertyApi.packages, 'Diamond'),
    },
    createdAt: propertyApi.created_at,
  };

  function _getPackageFromApi(
    propertyPackages: PropertyPackageApi[],
    packageName: string,
  ): PropertyPackage | undefined {
    const foundPackage = propertyPackages.find(
      (propertyPackage: PropertyPackageApi) => {
        return propertyPackage.name === packageName;
      },
    );

    if (foundPackage) {
      return {
        imageUrl: foundPackage.image_url,
        image: null,
        price: foundPackage.price,
        azqAmount: foundPackage.azq_issued,
        description: foundPackage.description,
      };
    }
  }
}

export function _getImages(images: { id: number; url: string }[]): ImageFile[] {
  return images.map((image) => {
    return { id: image.id, preview: image.url, file: null };
  });
}

export function appToApi(property: Property): PropertyToApi {
  const getFinancialValues = () => {
    return property.financial.launchDate
      ? {
          launch_date: property.financial.launchDate,
          minimum_holding_time: property.financial.minimumHoldingTime,
          property_valuation: property.financial.propertyValuation,
        }
      : {
          minimum_holding_time: property.financial.minimumHoldingTime,
          property_valuation: property.financial.propertyValuation,
        };
  };
  return {
    id: property.id,
    name: property.name,
    description: property.description,
    number_of_rooms: property.numberOfRooms,
    rating: property.rating,
    opening_date: property.openingDate,
    website: property.website,
    operator_id: property.operatorId,
    type_id: property.typeId,
    features: property.features.map((feature) => feature.id),
    images: property.images.map((image: ImageFile) => {
      return image.id ? image.id : image.file;
    }),
    location: {
      country_name: property.location.countryName,
      country_code: property.location.countryCode,
      city: property.location.city,
      formatted_address: property.location.formattedAddress,
      latitude: property.location.latitude,
      longitude: property.location.longitude,
      description: property.locationDescription,
    },
    financial: getFinancialValues(),
    packages: {
      bronze: _getPackageToApi(property.packages, 'bronze'),
      silver: _getPackageToApi(property.packages, 'silver'),
      gold: _getPackageToApi(property.packages, 'gold'),
      diamond: _getPackageToApi(property.packages, 'diamond'),
    },
  };
}

function _getPackageToApi(
  propertyPackage: PropertyPackages,
  key: string,
): PropertyPackageToApi {
  let propertyPackageToSend = {
    azq_issued: propertyPackage[key].azqAmount,
    description: propertyPackage[key].description,
  };
  if (propertyPackage[key].image) {
    propertyPackageToSend['image'] = propertyPackage[key].image;
  }
  return propertyPackageToSend;
  return;
}
