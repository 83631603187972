import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Login } from './containers/auth';
import { Flip, ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './containers/shared/ProtectedRoute';
import { Dashboard } from './containers/shared/Dashboard';
import { ForgotPassword } from './containers/auth/ForgotPassword';
import { SetPassword } from './containers/auth/SetPassword';
import { Index as Roles } from './containers/roles/Index';
import { AdminList } from './containers/user/AdminList';
import { UserList } from './containers/user/UserList';
import { Edit as UserEdit } from './containers/user/Edit';
import { Create as UserCreate } from './containers/user/Create';
import { List as OperatorList } from './containers/operator/index';
import { Edit as OperatorEdit } from './containers/operator/Edit';
import { Index as PropertyList } from './containers/property/Index';
import { Create as PropertyCreate } from './containers/property/Create';
import { Edit as PropertyEdit } from './containers/property/Edit';
import { Show as PropertyShow } from './containers/property/Show';
import { Profile } from './containers/user/Profile';
import { OperatorPermissionsEnum } from 'src/constants/OperatorPermissionsEnum';
import { RolesPermissionsEnum } from 'src/constants/RolesPermissionsEnum';
import { UserPermissionsEnum } from 'src/constants/UserPermissionsEnum';
import { PropertyPermissionsEnum } from 'src/constants/PropertyPermissionsEnum';
import NotFound from './containers/shared/NotFound';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="flex flex-col h-screen max-w-screen dark bg-gray-50 dark:bg-gray-800 ">
        <Routes>
          <Route
            path="/"
            element={<Login />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/users/register"
            element={
              <SetPassword />
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/admins"
            element={
              <ProtectedRoute permission={UserPermissionsEnum.list}>
                <AdminList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/users"
            element={
              <ProtectedRoute permission={UserPermissionsEnum.list}>
                <UserList />
              </ProtectedRoute>
            }
          />         
          <Route
            path="/dashboard/users/:id/edit"
            element={
              <ProtectedRoute permission={UserPermissionsEnum.edit}>
                <UserEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/users/create"
            element={
              <ProtectedRoute permission={UserPermissionsEnum.create}>
                <UserCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/properties"
            element={
              <ProtectedRoute permission={UserPermissionsEnum.list}>
                <PropertyList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/properties/create"
            element={
              <ProtectedRoute permission={PropertyPermissionsEnum.create}>
                <PropertyCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/properties/:id/edit"
            element={
              <ProtectedRoute permission={PropertyPermissionsEnum.edit}>
                <PropertyEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/properties/:id"
            element={
              <ProtectedRoute permission={PropertyPermissionsEnum.show}>
                <PropertyShow />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/roles"
            element={
              <ProtectedRoute permission={RolesPermissionsEnum.list}>
                <Roles />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/operators"
            element={
              <ProtectedRoute permission={OperatorPermissionsEnum.list}>
                <OperatorList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/operators/:id/edit"
            element={
              <ProtectedRoute permission={OperatorPermissionsEnum.list}>
                <OperatorEdit />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      </div>
    </BrowserRouter>

    {/* Notifications */}
    <ToastContainer
      position="bottom-center"
      transition={Flip}
    />
    {/* /Notifications */}
  </React.StrictMode>,
);
