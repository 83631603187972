import Select from "react-select";

type Props = {
  options: Object[];
  defaultValue?: object;
  handleChange?: (e) => void;
};

const MultiSelect: React.FC<Props> = ({
  options,
  defaultValue,
  handleChange
}) => {

  return (
    <Select
    isMulti
    isClearable
    options={options}
    value={defaultValue}
    onChange={handleChange}
    styles={{
      option: provided => ({
        ...provided,
        backgroundColor: '#374050',
        color: 'white',
        "&:hover": {
          backgroundColor: "#248AFB"
        }
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: '#374050'
      }),
      singleValue: provided => ({
        ...provided,
        color: 'white',
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: '#374050',
        color: 'white',
        borderRadius: '0.5rem',
        border: state.isFocused ? '2px solid #00C055' : '1px solid #4B5462',
        boxShadow:  "none",
        minHeight: '42px',
        "&:hover": {
          border: '2px solid #00C055',
        },
        height: 'auto'
      }),
      input: provided => ({
        ...provided,
        color: 'white',
        
      }),
      multiValue: provided => ({
        ...provided,
        backgroundColor: '#00C055',
        color: 'white',
      }),
      multiValueLabel: (base) => ({
        ...base,
        color: 'white',
      }),
      valueContainer: (base) => ({
        ...base,
        height: 'auto'
      }),
      container: (base) => ({
        ...base,
        height: 'auto'
      }),
    }}
  />
)};

export default MultiSelect;