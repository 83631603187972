import { get, put, destroy, post } from 'src/api/Base';
import { objectToFormData } from 'src/helpers/ObjectHelper';
import { apiToApp, arrayApiToApp } from 'src/mappers/OperatorMappers';
import {
  Operator,
  OperatorApi,
  OperatorResponse,
  OperatorToApi,
  PaginatedOperatorList,
} from 'src/types/Operator';

async function paginatedList(
  page: number = 1,
  perPage: number = 10,
): Promise<PaginatedOperatorList> {
  const response = await get(`admin/operators?page=${page}&per_page=${perPage}`);

  return {
    operators: arrayApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    totalPages: response.data.data.meta.last_page,
  };
}

async function list(): Promise<Operator[]> {
  const response = await get(`admin/operators`);
  return arrayApiToApp(response.data.data.data);
}

async function update(
  id: number,
  payload: OperatorToApi,
): Promise<OperatorResponse> {
  const formData = objectToFormData(payload);
  formData.append('_method', 'put');

  const response = await post(`admin/operators/${id}`, formData);
  return response.data;
}

async function remove(id: number): Promise<OperatorResponse> {
  const response = await destroy(`admin/operators/${id}`);
  return response.data;
}

async function add(payload): Promise<OperatorResponse> {
  const response = await post(`admin/operators`, payload);
  return response.data;
}

async function show(id: number): Promise<Operator> {
  const response = await get(`admin/operators/${id}`);

  return apiToApp(response.data.data.data);
}

export { paginatedList, update, remove, list, add, show };