import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function notify(message: string, type: string): void {
  if(type === 'success') {
    toast.success(message, {
      position: 'bottom-center',
      autoClose: 3000,
      theme: 'colored',
      pauseOnHover: true,
      progress: undefined,
    });
  } else{
    toast.error(message, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: 'colored',
    });
  }
}