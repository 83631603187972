import { get, post, put, destroy } from './Base';
import { fromApiToApp, arrayFromApiToApp } from 'src/mappers/RoleMapper';
import {
  CreateRoleResponse,
  DeleteRoleResponse,
  EditRoleResponse,
  PaginatedRolesList,
  Role,
  RoleResponse,
  RoleToApi,
} from 'src/types/Roles';

async function paginatedList(page: number = 1, perPage: number = 10): Promise<PaginatedRolesList> {

  const response = await get(`admin/roles?page=${page}&per_page=${perPage}`);
  return {
    roles: arrayFromApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    totalPages: response.data.data.meta.last_page,
  };

}

async function list(): Promise<Role[]> {
  const response = await get(`admin/roles`);

  return arrayFromApiToApp(response.data.data.data);
}

async function show(roleId: number): Promise<Role> {
  const response: RoleResponse = await get(`admin/roles/${roleId}`);

  return fromApiToApp(response.data.data.data);
}

async function create(payload: RoleToApi): Promise<CreateRoleResponse> {
  const response = await post(`admin/roles`, payload);
  return response.data ;
}

async function update(id: number, payload: RoleToApi): Promise<EditRoleResponse> {
  const response = await put(`admin/roles/${id}`, payload);
  return response.data;
}

async function deleteRole(id: number): Promise<DeleteRoleResponse> {
  const response = await destroy(`admin/roles/${id}`);

  return response;
}


export {
  paginatedList,
  create,
  deleteRole,
  update,
  show,
  list
};
