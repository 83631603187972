import { get, post, put, destroy } from 'src/api/Base';
import { objectToFormData } from 'src/helpers/ObjectHelper';
import { arrayApiToApp, apiToApp, appToApi } from 'src/mappers/PropertyMapper';
import {
  PaginatedPropertyList,
  Property,
  PropertyResponse,
} from 'src/types/Property';

async function list(
  page: number = 1,
  perPage: number = 10,
): Promise<PaginatedPropertyList> {
  const response = await get(`properties?page=${page}&per_page=${perPage}`);

  return {
    properties: arrayApiToApp(response.data.data.data),
    currentPage: response.data.data.meta.current_page,
    totalPages: response.data.data.meta.last_page,
  };
}

async function show(id: number): Promise<Property> {
  const response = await get(`properties/${id}`);

  return apiToApp(response.data.data.data);
}

async function create(payload: Property): Promise<PropertyResponse> {
  const formattedPayload = appToApi(payload);
  const formData = objectToFormData(formattedPayload);

  const response = await post(`properties/create`, formData);
  return response.data;
}

async function update(
  id: number,
  payload: Property,
): Promise<PropertyResponse> {
  const formattedPayload = appToApi(payload);

  const formData = objectToFormData(formattedPayload);

  formData.append('_method', 'put');

  const response = await post(`properties/${id}`, formData);
  return response.data;
}

async function remove(id: number): Promise<PropertyResponse> {
  const response = await destroy(`properties/${id}`);
  return response.data;
}

export { create, list, show, remove, update };
