import { StarRating } from "src/components/shared/form/StarRating";
import SingleSelect from "src/components/shared/form/SingleSelect";
import MultiSelect from "src/components/shared/form/MultiSelect";
import { useState } from "react";
import { ImageFile, Location, Property } from "src/types/Property";
import { PackageForm } from "./PackageForm";
import { Operator } from "src/types/Operator";
import { todayFormattedForInput } from "src/helpers/DateHelper";
import LocationInput from "src/components/shared/form/LocationInput";
import { Accordion } from "flowbite-react";

type Props = {
  onPropertyCreate: (payload: Property) => void,
  operators: Operator[]
}

type selectOption = {
  value: number,
  label: string
}

type packageData = {
  image: File | null,
  price: number,
  azqAmount: number,
  description: string
}

export function CreateForm({ onPropertyCreate, operators }: Props) {
  const initialValue: Property = {
    name: '',
    description: '',
    numberOfRooms: 1,
    rating: 0,
    openingDate: '',
    website: '',
    operatorId: 1,
    typeId: 1,
    features: [],
    images: [],
    location: {
      countryName: '',
      countryCode: '',
      city: '',
      formattedAddress: '',
      latitude: 0,
      longitude: 0,
    },
    locationDescription: '',
    financial: {
      launchDate: '',
      minimumHoldingTime: 18,
      propertyValuation: 0
    },
    packages: {
      bronze: {
        image: null,
        price: 0,
        azqAmount: 0,
        description: ''
      },
      silver: {
        image: null,
        price: 0,
        azqAmount: 0,

        description: ''
      },
      gold: {
        image: null,
        price: 0,
        azqAmount: 0,
        description: ''
      },
      diamond: {
        image: null,
        price: 0,
        azqAmount: 0,
        description: ''
      },
    }
  }

  const typeOptions: selectOption[] = [
    { value: 1, label: 'Resort' },
    { value: 2, label: 'Boutique' },
    { value: 3, label: 'Airport' },
    { value: 4, label: 'Motel' }
  ]

  const facilityOptions: selectOption[] = [
    { value: 1, label: 'Restaurant' },
    { value: 2, label: 'Non-smoking rooms' },
    { value: 3, label: 'Fitness center' },
    { value: 4, label: 'Facilities for disabled guests' },
    { value: 5, label: 'Private Parking' },
    { value: 6, label: 'Free WiFi' },
    { value: 7, label: 'Family rooms' },
    { value: 8, label: 'Tea/Coffee Maker' },
    { value: 9, label: 'Bar' },
  ]

  const [property, setProperty] = useState<Property>(initialValue);
  const defaultTypeValue: selectOption = typeOptions.find(option => option.value === 1);
  const [selectedType, setSelectedType] = useState(defaultTypeValue);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const handleChangeRating = (newRating: number): void => {
    setProperty({ ...property, ['rating']: newRating });
  };

  const handleTypeChange = (selectedType: selectOption): void => {
    setSelectedType(selectedType);
    setProperty({ ...property, ['typeId']: selectedType.value });
  };

  const handleFeaturesChange = (selectedFeatures: selectOption[]): void => {
    const features = selectedFeatures.map((obj) => { return { id: obj.value, name: obj.label } });
    setSelectedFeatures(selectedFeatures);
    setProperty({ ...property, ['features']: features });
  };

  const handleInputChange = (event: any): void => {
    const { name, value } = event.target;
    if (name === 'launchDate' || name === 'minimumHoldingTime' || name === 'propertyValuation') {
      setProperty(() => ({
        ...property,
        financial: { ...property.financial, [name]: value },
      }));
    } else {
      setProperty({ ...property, [name]: value });
    }
  };

  function handleBronzeChange(packageData: packageData) {
    setProperty(() => ({
      ...property,
      packages: {
        ...property.packages,
        bronze: {
          image: packageData.image,
          price: packageData.price,
          azqAmount: packageData.azqAmount,
          description: packageData.description
        }
      }
    }));
  }

  function handleSilverChange(packageData: packageData) {
    setProperty(() => ({
      ...property,
      packages: {
        ...property.packages,
        silver: {
          image: packageData.image,
          price: packageData.price,
          azqAmount: packageData.azqAmount,
          description: packageData.description
        }
      }
    }));
  }

  function handleGoldChange(packageData: packageData) {
    setProperty(() => ({
      ...property,
      packages: {
        ...property.packages,
        gold: {
          image: packageData.image,
          price: packageData.price,
          azqAmount: packageData.azqAmount,
          description: packageData.description
        }
      }
    }));
  }

  function handleDiamondChange(packageData: packageData) {
    setProperty(() => ({
      ...property,
      packages: {
        ...property.packages,
        diamond: {
          image: packageData.image,
          price: packageData.price,
          azqAmount: packageData.azqAmount,
          description: packageData.description
        }
      }
    }));
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const imagesArray: ImageFile[] = [];

    const filesLength = files.length > 5 ? 5 : files.length;

    for (let i = 0; i < filesLength; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = () => {
        imagesArray.push({ file, preview: reader.result as string });

        if (i === filesLength - 1) {
          setProperty(() => ({
            ...property,
            images: imagesArray
          }));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleLocationChange = (location: Location) => {
    setProperty(() => ({
      ...property,
      location: location
    }));
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    onPropertyCreate(property);
    // setProperty(initialValue);
    setSelectedType(defaultTypeValue);
  }

  return (
    <div className="h-full p-4 mb-4 bg-white shadow sm:p-6 xl:p-8 dark:bg-gray-800">
      <form
        action="#"
        className="h-full"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={property.name}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 my-date-input"
              required
            ></input>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Description
            </label>
            <textarea
              name="description"
              rows={5}
              id="description"
              value={property.description}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            ></textarea>
          </div>

          <div className="col-span-6">
            <input
              type="file"
              multiple
              accept="image/*"
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              onChange={handleFileChange}
            />
          </div>

          <div className="col-span-6">
            <div className="flex flex-row flex-wrap gap-x-[15px]">
              {property.images.map((image, index) => (
                <img
                  className="w-44 h-44"
                  key={index}
                  src={image.preview}
                  alt={`Image ${index}`}
                />
              ))}
            </div>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="numberOfRooms"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Number of Rooms
            </label>
            <select
              name="numberOfRooms"
              id="numberOfRooms"
              value={property.numberOfRooms}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Hotel Rating
            </label>
            <StarRating
              value={property.rating}
              hover={property.rating}
              onChangeRating={handleChangeRating}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Type
            </label>
            <SingleSelect
              defaultValue={selectedType}
              handleChange={handleTypeChange}
              options={typeOptions}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="openingDate"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Opening Date
            </label>
            <input
              type="date"
              name="openingDate"
              id="openingDate"
              // min={todayFormattedForInput()}
              value={property.openingDate}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 my-date-input"
              required
            ></input>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="operatorId"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Operator
            </label>
            <select
              name="operatorId"
              id="operatorId"
              value={property.operatorId}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            >
              {operators.map((operator) => (
                <option
                  value={operator.id}
                  key={operator.id}
                >
                  {operator.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="website"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Website
            </label>
            <input
              type="text"
              name="website"
              id="website"
              value={property.website}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            ></input>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Features
            </label>
            <MultiSelect
              defaultValue={selectedFeatures}
              handleChange={handleFeaturesChange}
              options={facilityOptions}
            />
          </div>

          <div className="col-span-6">
            <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="launchDate"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Launch Date
            </label>
            <input
              type="date"
              name="launchDate"
              id="launchDate"
              min={todayFormattedForInput()}
              value={property.financial.launchDate}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 my-date-input"
              required
            ></input>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="minimumHoldingTime"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Minimum Holding Time (in months)
            </label>
            <input
              type="number"
              min="18"
              name="minimumHoldingTime"
              id="minimumHoldingTime"
              value={property.financial.minimumHoldingTime}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            ></input>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="propertyValuation"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Property Valuation (in €)
            </label>
            <input
              type="number"
              min="0"
              name="propertyValuation"
              id="propertyValuation"
              value={property.financial.propertyValuation}
              onChange={handleInputChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            ></input>
          </div>

          <div className="col-span-6">
            <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          <div className="col-span-6">
            <label
              htmlFor=" "
              className="block mb-8 font-medium text-gray-900 text-md dark:text-white"
            >
              Location
            </label>
            <label
              htmlFor="Location"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Address
            </label>
            <LocationInput onHandleChange={handleLocationChange}>
              <div>
                <label
                  htmlFor="locationDescription"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Location Description
                </label>
                <textarea
                  rows={5}
                  name="locationDescription"
                  id="locationDescription"
                  value={property.locationDescription}
                  onChange={handleInputChange}
                  className="shadow-sm h-full resize-none bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                ></textarea>
              </div>
            </LocationInput>
          </div>

          <div className="col-span-6">
            <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          
          <div className="col-span-6">
            <label
              htmlFor=" "
              className="block mb-8 font-medium text-gray-900 text-md dark:text-white"
            >
              Packages
            </label>
            <Accordion flush={true} alwaysOpen={true}>
              <Accordion.Panel>
                <Accordion.Title style={{padding: 10}}>
                  Bronze
                </Accordion.Title>
                <Accordion.Content style={{padding: 20}}>
                  <PackageForm
                    submit={handleBronzeChange}
                  />
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title style={{padding: 10}}>
                  Silver
                </Accordion.Title>
                <Accordion.Content style={{padding: 20}}>
                  <PackageForm
                    submit={handleSilverChange}
                  />
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel >
                <Accordion.Title style={{padding: 10}}>
                  Gold
                </Accordion.Title>
                <Accordion.Content style={{padding: 20}}>
                  <PackageForm
                    submit={handleGoldChange}
                  />
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title style={{padding: 10}}>
                  Diamond
                </Accordion.Title>
                <Accordion.Content style={{padding: 20}}>
                <PackageForm
                  submit={handleDiamondChange}
                />
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>

          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-auto"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
