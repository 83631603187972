import axios from 'axios';
import { GetAccessToken } from 'src/services/auth/AuthService';
import { notify } from 'src/services/NotificationService';

const baseUrl: String = process.env.REACT_APP_API_BASE_URL;

function getHeaders(): any {
  const token: String = GetAccessToken();
  const headers: any = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
  };
  const config = { headers };

  return config;
}

async function get(url: string): Promise<any> {
  try {
    return await axios.get(`${baseUrl}/${url}`, getHeaders());
  } catch (error) {
    _handleError(error.response);
  }
}

async function post(url: string, payload?: any): Promise<any> {
  try {
    return await axios.post(`${baseUrl}/${url}`, payload, getHeaders());
  } catch (error) {
    _handleError(error.response);
  }
}

async function put(url: string, payload: any): Promise<any> {
  try {
    return await axios.put(`${baseUrl}/${url}`, payload, getHeaders());
  } catch (error) {
    _handleError(error.response);
  }
}

async function destroy(url: string): Promise<any> {
  try {
    return await axios.delete(`${baseUrl}/${url}`, getHeaders());
  } catch (error) {
    _handleError(error.response);
  }
}

function _handleError(error): void {
  notify(error.data.message || 'Error', 'error');
  throw error;
}
export { get, post, put, destroy };
