import { useRef, useState } from 'react';
import { appToApi } from 'src/mappers/OperatorMappers';
import { Operator, OperatorApi, OperatorToApi } from 'src/types/Operator';
import placeholderImage from 'src/assets/images/placeholder.jpg';

type Props = {
  operator: Operator;
  onOperatorUpdate: (operatorId: number, payload: OperatorApi) => void;
};

export function EditForm({ operator, onOperatorUpdate }: Props) {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValues, setInputValues] = useState<Operator>(operator);
  const [logo, setLogo] = useState<File>(undefined);
  let inputElement = useRef<HTMLInputElement>(undefined);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target.name;
    const file = event.target.files[0];
    const reader = new FileReader();
    setLogo(file);
    reader.onload = () => {
      setInputValues({ ...inputValues, [name]: reader.result as string });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    setIsSubmitting(true);
    event.preventDefault();

    const payload: OperatorApi = appToApi(inputValues, logo);
    
    onOperatorUpdate(operator.id, payload);
    setIsSubmitting(false);
  };

  return (
    <div className="h-full p-4 mb-4 bg-white shadow sm:p-6 xl:p-8 dark:bg-gray-800">
      <form
        action="#"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <div className="relative w-48 h-48 mx-auto overflow-hidden rounded-full">
              <input ref={input => inputElement.current = input} name="logoUrl" onChange={handleImageChange} type="file" className="hidden" />
              <div onClick={() => { inputElement.current.click() }} className="absolute top-0 left-0 w-full h-full group hover:bg-opacity-50 hover:bg-black">
                <span className="absolute items-center justify-center hidden w-full h-full m-auto text-center text-white cursor-pointer group-hover:flex">
                  change logo
                </span>
              </div>
              <img src={inputValues.logoUrl ?? placeholderImage} alt="" className="object-cover w-full h-full" />
            </div>
          </div>
          <div className="col-span-6">
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Name
            </label>
            <input
              type="text"
              value={inputValues.name || ''}
              onChange={handleChange}
              name="name"
              id="name"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Bonnie"
              required
            />
          </div>
          <div className="col-span-6">
            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Description
            </label>
            <textarea
              value={inputValues.description || ''}
              onChange={handleChange}
              name="description"
              id="description"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Green"
              required
            />
          </div>
          
          <div className="col-span-6">
            <button
              disabled={isSubmitting}
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              type="submit"
            >
              Save all
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
