import { RolesPermissionsEnum } from 'src/constants/RolesPermissionsEnum';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { Button } from 'src/components/shared/Button';

type Props = {
  onEditModalButton: () => void;
  role: any;
  onDelete: (roleId: number) => void;
};

export function Item({ onEditModalButton, role: role, onDelete }: Props) {

  return (
    <>
      <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
          {role.name}
        </td>
        <td className="px-6 py-3 text-base text-center text-gray-900 dark:text-white">
          <div className="inline-block mx-auto">
            <p className="break-words">
              {role.permissions.map((permission: { name: string; id: number }, index: number) => {
                const comma = index < role.permissions.length - 1 ? ', ' : '';
                return (
                  <span key={permission.id}>
                    {permission.name}{comma}
                  </span>
                );
              },
              )}
            </p>
          </div>
        </td>
        <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">
          {hasPermission(RolesPermissionsEnum.edit) &&

            <button
              type="button"
              onClick={onEditModalButton}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-500 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-500"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                <path
                  fillRule="evenodd"
                  d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Edit
            </button>
          }
          {hasPermission(RolesPermissionsEnum.delete) &&

            <Button
              styles="danger"
              action={() => onDelete(role.id)}>
              <svg
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                <path
                  fillRule="evenodd"
                  d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Delete
            </Button>
          }
        </td>
      </tr>
    </>
  );
}
