import { JSONToString, stringToJSON } from 'src/helpers/Parsers';
import { daysToMs } from 'src/helpers/DateHelper';

function set(name: string, value: object, days: number = 30): void {
  let expires = '';

  if (days) {
    const date = new Date();
    const daysInMs = daysToMs(days);

    date.setTime(date.getTime() + daysInMs);

    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${
    JSONToString(value) || ''
  }${expires}; path=/`;
}

function get(name: string): any {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(`${name}=`)) {
      const value = cookie.substring(name.length + 1);
      return stringToJSON(value);
    }
  }

  return null;
}

function remove(name: string): void {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}

function clear() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export { set, get, remove, clear };