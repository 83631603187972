import { DashboardLayout } from 'src/layouts';
import { Header } from 'src/components/shared/dashboard/Header';
import { Operator, OperatorResponse } from 'src/types/Operator';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { show, update } from 'src/api/Operator';
import { notify } from 'src/services/NotificationService';
import { EditForm } from 'src/components/operator/forms/EditForm';

export const Edit: React.FC = () => {
  const [operator, setOperator] = useState<Operator>();
  const { id } = useParams();

  useEffect(() => {
    const getOperator = async (): Promise<void> => {
      const property = await show(parseInt(id));
      setOperator(property);
    };
    getOperator();
  }, [id]);

  const handleUpdate = async (
    operatorId: number,
    payload: any,
  ): Promise<void> => {
    const response: OperatorResponse = await update(operatorId, payload);
    notify(response.message, 'success');
  };

  return (
    <DashboardLayout>
      <Header
        heading={'Edit Operator'}
      />
      {operator && (
        <EditForm
          operator={operator}
          onOperatorUpdate={handleUpdate}
        />
      )}
    </DashboardLayout>
  );
};