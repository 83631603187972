import { Link } from 'react-router-dom';
import { OperatorPermissionsEnum } from 'src/constants/OperatorPermissionsEnum';
import { PropertyPermissionsEnum } from 'src/constants/PropertyPermissionsEnum';
import { RolesPermissionsEnum } from 'src/constants/RolesPermissionsEnum';
import { UserPermissionsEnum } from 'src/constants/UserPermissionsEnum';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';

type Props = {
  isOpen: boolean;
};
export const Sidebar: React.FC<Props>= ({isOpen}: Props) => {
  return (
    <aside
      id="sidebar"
      className={`fixed top-0 left-0 z-20 flex flex-col md:flex flex-shrink-0 w-64 h-full pt-16 duration-75 lg:flex transition-width ${isOpen ? 'flex' : 'hidden'}`}
      aria-label="Sidebar"
    >
      <div className="relative flex flex-col flex-1 h-full min-h-0 pt-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
          <div className="flex-1 px-3 space-y-1 bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            <ul className="pb-2 space-y-2">
              <li>
                {hasPermission(UserPermissionsEnum.list) &&
                  <Link
                      to="/dashboard/admins"
                      className={location.pathname.includes('admins')  ? 
                        'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700' 
                        : 
                        'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '}
                    >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Admins</span>
                  </Link>
                }
              </li>

              <li>
                {hasPermission(UserPermissionsEnum.list) &&
                  <Link
                    to="/dashboard/users"
                    className={location.pathname.includes('users')  ? 
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700' 
                      : 
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '}
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Users</span>
                  </Link>
                }
              </li>
              
              {/* Roles */}
              <li>
                {hasPermission(RolesPermissionsEnum.list) &&
                  <Link
                    to="/dashboard/roles"
                    className={location.pathname.includes('roles')  ? 
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700' 
                      : 
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '}
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Roles</span>
                  </Link>
                }
              </li>

              {/* Operators */}
              <li>
                {hasPermission(OperatorPermissionsEnum.list) &&
                  <Link
                    to="/dashboard/operators"
                    className={location.pathname.includes('operators')  ? 
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700' 
                      : 
                      'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '}
                  >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
                    </svg>

                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Operators
                    </span>
                  </Link>
                }
              </li>

              <li>
                {hasPermission(PropertyPermissionsEnum.list) &&
                  <Link
                      to="/dashboard/properties"
                      className={location.pathname.includes('properties')  ? 
                        'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-gray-200 bg-gray-100  dark:bg-gray-700' 
                        : 
                        'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700 '}
                    >
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M1 2.75A.75.75 0 011.75 2h10.5a.75.75 0 010 1.5H12v13.75a.75.75 0 01-.75.75h-1.5a.75.75 0 01-.75-.75v-2.5a.75.75 0 00-.75-.75h-2.5a.75.75 0 00-.75.75v2.5a.75.75 0 01-.75.75h-2.5a.75.75 0 010-1.5H2v-13h-.25A.75.75 0 011 2.75zM4 5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM4.5 9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM8 5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM8.5 9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM14.25 6a.75.75 0 00-.75.75V17a1 1 0 001 1h3.75a.75.75 0 000-1.5H18v-9h.25a.75.75 0 000-1.5h-4zm.5 3.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zm.5 3.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Properties
                    </span>
                  </Link>
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};
