
type Props = {
  heading: String;
  createButton?: Object;
  createButtonModal?: Object;
  handleOnClick?: () => void,
};

export function Header({
  heading,
  createButton,
  createButtonModal,
  handleOnClick
}: Props) {
  return (
    <div className=" p-5 pb-8 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700">
      <div className="w-full mb-1">
        <div className="mb-4">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
          {heading}
          </h1>
        </div>
      </div>
    </div>
  );
}
