import { Property } from 'src/types/Property'
import { Link } from 'react-router-dom';
import { Button } from 'src/components/shared/Button';
import { hasPermission } from 'src/helpers/roles/permissionsHelper';
import { PropertyPermissionsEnum } from 'src/constants/PropertyPermissionsEnum';
import { Operator } from 'src/types/Operator';
import { toReadableString } from 'src/helpers/DateHelper';

type Props = {
  property: Property;
  operator: Operator;
  onDelete: (userId: number) => void;
};

export function Item({ property, operator, onDelete }: Props) {
  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
      <td className="flex items-center justify-center p-4 space-x-6 text-center md:mr-12 whitespace-nowrap lg:mr-0">
        <img
          className="w-10 h-10 rounded-full"
          aria-label={property.images[0]?.preview}
          src={property.images[0]?.preview}
          alt="User avatar"
        />
      </td>
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        {property.name}
      </td>
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {operator?.name}
        </div>
      </td>
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {property.location.countryName}
        </div>
      </td>
      <td className="px-6 py-3 text-base font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {toReadableString(new Date(property.createdAt))}
        </div>
      </td>
      <td className="px-6 py-3 space-x-2 text-center whitespace-nowrap">
        {hasPermission(PropertyPermissionsEnum.edit) &&

          <Link
            to={`/dashboard/properties/${property.id}/edit`}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-500 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-500"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              ></path>
            </svg>
            Edit property
          </Link>
        }
        {hasPermission(PropertyPermissionsEnum.delete) &&
          <Button
            styles="danger"
            action={() => onDelete(property.id)}
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              ></path>
            </svg>
            Delete
          </Button>
        }
      </td>
    </tr>
  );
}
